import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Missions } from "./pages/Missions/Missions";
import { SingleMission } from "./pages/SingleMission/SingleMission";

import "./App.css";
import { Intro } from "./pages/Intro/Intro";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/">
            <Intro />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
